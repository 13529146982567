<template>
    <Versioned @selected="versioned = $event" readonly :version="version"></versioned>
    <q-card>
      <q-toolbar class="bg-white">
        <q-toolbar-title>
          Lookup Builder
        </q-toolbar-title>
        <template v-if="tab !== 'lookups'">
          <div class="q-pa-sm" v-if="issaving">Saving Flowsheet...<q-linear-progress indeterminate></q-linear-progress></div>
          <div class="q-pa-sm text-warning" v-else-if="unsaved !== saved">Changes need Saving</div>
          <div class="q-pa-sm text-positive" v-else>Up to Date</div>
        </template>
        <q-btn flat color="primary" round icon="close" @click="$router.back()"></q-btn>
      </q-toolbar>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
          <q-tab name="details" label="Details" />
          <q-tab name="lookup_fields" label="Lookup Fields" />
          <q-tab name="fields" label="Advanced Fields" />
          <q-tab name="lookups" label="Lookups" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="details">
              <q-input outlined label="Lookup Type Name" v-model="lookup_type.field_name"></q-input>
              <span class="q-ma-md">Actions</span>
              <q-input
                  v-for="(action, index) in lookup_type.actions"
                  v-model="lookup_type.actions[index].action_name"
                  :class="{ 'q-ma-md': true, 'active-row': selected_action ? action.uid === selected_action.uid : false}"
                  @click="selected_action = action"
                  outlined
                  label="Action"
              >
                <template v-if="action.special" v-slot:append>
                  {{ action.special }}
                </template>
                <template v-slot:after>
                  <q-btn icon="close" round flat color="negative" @click="lookup_type.actions.splice(index, 1)"></q-btn>
                  <q-btn class="q-ma-md" round flat icon="edit" @click="edit_action(action)"></q-btn>
                </template>
              </q-input>
              <q-btn-dropdown color="primary" label="Add Action">
                <q-list>
                  <q-item clickable v-close-popup @click="add_action()">
                    <q-item-section>
                      <q-item-label>Action</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="add_action('Start')">
                    <q-item-section>
                      <q-item-label>Start Action</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="add_action('End')">
                    <q-item-section>
                      <q-item-label>End Action</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
          </q-tab-panel>
          <q-tab-panel name="lookup_fields">
            <TipTap v-model="lookup_type.lookup_render" :edit="true" :lookup_type="lookup_type" :data="data" show_lookup_type></TipTap>
            <AdvancedForm
                :fields="lookup_type.lookup_fields"
                :data="data.lookup.data"
                :selected_field="advanced_field"
                @selected="activate_field"
                @update:fields="lookup_type.lookup_fields = $event"
            ></AdvancedForm>
            <q-card-actions>
              <q-btn @click="add_lookup_field" icon="add" label="Add Lookup Field"></q-btn>
            </q-card-actions>
          </q-tab-panel>
          <q-tab-panel name="fields">
            <TipTap v-model="lookup_type.advanced_render" :edit="true" :lookup_type="lookup_type" :data="data" show_lookup_type show_advanced></TipTap>
            <AdvancedForm
                :fields="lookup_type.fields"
                :data="data"
                :selected_field="advanced_field"
                @selected="activate_field"
                @update:fields="lookup_type.fields = $event"
            ></AdvancedForm>
            <q-card-actions>
              <q-btn @click="add_field" icon="add" label="Add Field"></q-btn>
            </q-card-actions>
          </q-tab-panel>
          <q-tab-panel name="lookups">
              <q-table v-if="lookup_type"
                  class="q-pa-md"
                  title="Lookup Types"
                  style="height: calc(100vh - 120px);"
                  :columns="columns" :rows="lookups" row-key="uid"
                    separator="vertical" :loading="loading" :filter="filter"
                  v-model:pagination="pagination"
                  @request="onRequest"
                  :grid="$q.screen.xs"
                  >
                  <template v-slot:top-left>
                    <div>
                      <div class="row text-h5 text-primary q-mb-md">{{ lookup_type.field_name }} Lookups</div>
                      <div class="row">
                        <q-input outlined dense debounce="300" v-model="filter" placeholder="Search">
                          <template v-slot:append>
                            <q-icon name="search" />
                          </template>
                        </q-input>
                      </div>
                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-btn :disable="!versioned || versioned.uid==='published'" color="primary" icon="add" label="New Lookup" @click="new_lookup">
                    </q-btn>
                  </template>

                  <template v-slot:body-cell="props">
                    <q-td auto-width>
                      <component :edit="edit_row===props.row.uid" outlined dense :is="lookup(props.col.data_type)" v-model="props.row[props.col.uid]" :field="props.col"></component>
                    </q-td>
                  </template>
                  <template v-slot:body-cell-actions="props">
                    <q-td auto-width>
                      <TipTap v-model="lookup_type.lookup_render" :edit="false" :lookup_type="lookup_type" :data="{lookup: {data: props.row}}"></TipTap>
                      <template v-if="edit_row!==props.row.uid">
                        <q-btn class="q-ma-sm" round color="secondary" size="sm" icon="edit" @click="edit_row = props.row.uid"></q-btn>
                        <q-btn class="q-ma-sm" round color="negative" size="sm" icon="close" @click="d_lookup = props.row; confirm_delete = true;"></q-btn>
                      </template>
                      <template v-else>
                        <q-btn class="q-ma-sm" round color="secondary" size="sm" icon="save" @click="save_lookup(props.row)"></q-btn>
                        <q-btn class="q-ma-sm" round size="sm" icon="close" @click="edit_row = null"></q-btn>
                      </template>
                    </q-td>
                  </template>

              </q-table>
          </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-drawer v-if="selected_action && tab=='details'" side="right" :model-value="true">
      <div v-if="selected_action">
        <div class="q-pa-md bg-primary text-white text-center text-h5">Action Settings</div>
        <q-input class="q-ma-md" v-model="selected_action.action_name" outlined label="Field Name"></q-input>
        <q-select
            class="q-ma-md"
            v-model="selected_action.flowsheet"
            outlined
            label="Action Flowsheet"
            :options="action_flowsheets"
            map-options
            emit-value
        ></q-select>
      </div>
    </q-drawer>
    <FieldDrawer v-if="advanced_field && ['lookup_fields', 'fields'].includes(tab)" v-model="advanced_field" :flowsheet="flowsheet" :version="version" @remove="remove()"></FieldDrawer>
    <q-dialog v-model="confirm_delete">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="delete" color="negative" text-color="white" />
          <span class="q-ml-sm">Are you sure you want to delete the Lookup?</span><br>
          <span>This will permanently delete the lookup</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn label="Delete" color="negative" v-close-popup @click="delete_lookup(d_lookup)" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  <q-inner-loading
      :showing="loading"
      label="Please wait..."
      label-class="text-secondary"
      label-style="font-size: 1.1em"
    />
</template>

<script>
import {ref, onMounted, computed, watch, provide, toRef} from 'vue';
  import { useFlowsheetStore } from "../stores/flowsheet";
  import Versioned from "../components/Versioned.vue";
  import { generate } from "xksuid";
  import { useRouter} from "vue-router";
  import AdvancedForm from "../components/flowsheets/AdvancedForm.vue";
  import TipTap from "../components/TipTap.vue";
  import {debounce} from "quasar";
  import {storeToRefs} from "pinia";
  import { lookup } from "../composables/patient_data";
  import FieldDrawer from "../components/FieldDrawer.vue";

  export default {
  name: "Lookups",
  components: {
    AdvancedForm,
    Versioned,
    TipTap,
    FieldDrawer
  },
  props: ['flowsheet_uid', 'lookup_type_uid', 'version', 'from'],
  setup(props){
    const router = useRouter();
    const issaving = ref(false);
    const saved = ref(false);
    const unsaved = ref(false);
    const tab = ref('lookups');
    const lookup_type = ref(null);
    const versioned = ref(null);
    const flowsheetStore = useFlowsheetStore();
    const {flowsheet, active_field} =storeToRefs(flowsheetStore);
    const loading = ref(false);
    const filter = ref('');
    const lookups = ref([]);
    const edit_row = ref(null);
    const advanced_field = ref(null);
    const data = ref({lookup: {data: {}}})
    const selected_action = ref(null);
    const d_lookup = ref(null);
    const confirm_delete = ref(false);
    const pagination = ref({
        rowsNumber: 10,
        page: 1,
        rowsPerPage: 50,
        sortBy: null,
        descending: false,
    })

    provide("versioned", versioned);
    provide('flowsheet', flowsheet);

    onMounted(() => {
        loading.value = true;
        if(!flowsheet.value){
            flowsheetStore.GetFlowsheet(props.flowsheet_uid, props.version).then(() => {
            })
        }

        flowsheetStore.GetLookupType(props.version, props.flowsheet_uid, props.lookup_type_uid, props.from).then(data => {
          lookup_type.value = data.lookup_type;
          //columns.value = lookup_type.value.lookup_fields
          //columns.value.push({label: 'Actions', name: 'actions', field: 'actions', align: 'left'})
          onRequest({
            pagination: pagination.value,
            filter: undefined
          })
        });


    });

    const columns = computed(() => {
      const c = lookup_type.value.lookup_fields.map(field => {
        return {
          ...field,
          name: field.uid,
          label: field.field_name,
          field: field.uid,
          align: 'left',
          data_type: field.data_type
        }
      })
      c.push({label: 'Actions', name: 'actions', field: 'actions', align: 'left'})
      return c;
    })

    const action_flowsheets = computed(() => {
      return lookup_type.value.lookup_fields.filter(f => f.data_type === 'Flowsheet').map(f => {
        return {
          label: f.field_name,
          value: f.uid
        }
      })
    })

    function onRequest(p) {
        const { page, rowsPerPage, sortBy, descending } = p.pagination
        const filter = p.filter
        loading.value = true
        edit_row.value = null;

        flowsheetStore.GetLookups(
            props.lookup_type_uid,
            page,
            rowsPerPage,
            sortBy,
            descending,
            filter).then(data => {
            if(typeof  data === 'string'){
              loading.value = false
              return
            }
            pagination.value.rowsNumber = data.total
            lookups.value = data.lookups.map(l => {
              return { uid: l.uid, ...l.data}
            })

            pagination.value.page = page;
            pagination.value.rowsPerPage = rowsPerPage
            pagination.value.sortBy = sortBy
            pagination.value.descending = descending

            loading.value = false
        });
    }

     function save_lookup_type() {
      saved.value = unsaved.value;
      issaving.value = true;
      lookup_type.value.flowsheet_uid = props.flowsheet_uid;
      lookup_type.value.flowsheet_version = props.version;
      lookup_type.value.uid = props.lookup_type_uid;
      flowsheetStore.SaveLookupType(lookup_type.value).then(f => {
        console.log(f);
        flowsheet.value.lookup_data[f.lookup_type.uid] = f.lookup_type
        issaving.value = false;
        if(unsaved.value > saved.value) {
          save_lookup_type();
        }
      });
    }
    save_lookup_type = debounce(save_lookup_type, 1000);

    watch(lookup_type, (newValue, oldValue) => {
      // fires on nested property mutations
      // Note: `newValue` will be equal to `oldValue` here
      // because they both point to the same object!
        console.log('Lookup Type Watch Fired')
        if (lookup_type.value) {
          if (!loading.value) {
            unsaved.value++;
            if (!issaving.value) {
              save_lookup_type();
            }
          }
        }
    },         {
      deep: true
    });

    return {
      selected_action,
      lookup_render: ref(null),
      advanced_render: ref(null),
      flowsheet,
      saved,
      unsaved,
      issaving,
      tab,
      data,
      advanced_field,
      edit_row,
      versioned,
      lookup_type,
      columns,
      action_flowsheets,
      lookups,
      loading,
      filter,
      pagination,
      confirm_delete,
      d_lookup,
      onRequest,
      new_lookup(){
        const uid = generate();
        const new_lookup = {
          uid: uid,
        }
        for(let field of lookup_type.value.lookup_fields) {
          new_lookup[field.uid] = null;
        }
        lookups.value.push(new_lookup)
        edit_row.value = uid
      },
      save_lookup(lookup){
        loading.value = true;
        lookup['lookup_type'] = props.lookup_type_uid;
        flowsheetStore.SaveLookup(lookup).then((data) => {
          loading.value = false;
          edit_row.value = null;
          lookup = { ...data}
        });
      },
      delete_lookup(lookup){
        loading.value = true;
        lookup['lookup_type'] = props.lookup_type_uid;
        flowsheetStore.DeleteLookup(lookup).then((data) => {
          loading.value = false;
          edit_row.value = null;
          lookups.value = lookups.value.filter(l => l.uid !== lookup.uid)
        });
      },
      add_lookup_field(){
        const field =  {
          uid: generate(),
          field_name: '',
          data_type: 'Text',
          field_units: [],
          field_options: [],
          help: ''
        }
        lookup_type.value.lookup_fields.push(field);

      },
      add_field(){
        const field =  {
          uid: generate(),
          field_name: '',
          data_type: 'Text',
          field_units: [],
          field_options: [],
          help: ''
        }
        lookup_type.value.fields.push(field);
      },
      activate_field(field){ // called when a field is selected
        console.log("activate_field", field)
        advanced_field.value = field;
      },
      add_action(special){
        if(!lookup_type.value.actions) lookup_type.value.actions = [];
        lookup_type.value.actions.push({action_name: '', special: special});
      },
      edit_action(action){
        active_field.value = action
        router.push(`/lookups/${props.version}/${props.flowsheet_uid}/advanced/${lookup_type.value.uid}/action/${action.uid}`)
      },
      remove() {
        //remove advanced_field from lookup_type.value.fields
        const index = lookup_type.value.fields.indexOf(advanced_field.value);
        if (index > -1) {
          lookup_type.value.fields.splice(index, 1);
        }
        //remove advanced_field from lookup_type.value.lookup_fields
        const index2 = lookup_type.value.lookup_fields.indexOf(advanced_field.value);
        if (index2 > -1) {
          lookup_type.value.lookup_fields.splice(index2, 1);
        }
        advanced_field.value = null;
      },
      lookup,
      show: ref(true)
    }
  }

}
</script>

<style scoped>

</style>
